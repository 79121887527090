import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import { UseTradeHistoryData } from "../utils/data";
import React, { useState } from "react";

import Navbar from "../Navbar/Navbar";
import styles from "./HomePage.module.css";
import TableComponent from "./TableComponent";
import Moment from "moment";

const style = {
  watchlistHeading: "text-white text-xl text-center",
  tagDiv1: "text-sm bg-[#1A1A1D] text-white/70 py-2 px-4 rounded-sm mr-3",
  tagDiv2: "text-sm bg-[#1A1A1D] text-white/70 py-2 px-4 rounded-sm ml-3",
};
const Dashboard = () => {
  const [state, setState] = useState("India");

  const { data: tradeHistory } = UseTradeHistoryData();

  const niftyData = tradeHistory && tradeHistory.data.data;
  const niftySellData = tradeHistory && tradeHistory.data.sell_data;

  const portfolio = tradeHistory && tradeHistory.data.mappings;

  const indiaData = portfolio
    ? portfolio.filter(
        (client) =>
          client.country === "INDIA" && client.display_on_dashboard === true
      )
    : [];
  const usaData = portfolio
    ? portfolio.filter(
        (client) =>
          client.country === "USA" && client.display_on_dashboard === true
      )
    : [];

    console.log("niftyData", niftyData);


  return (
    <div>
      <Navbar state={state} setState={setState} />
      {state === "India" ? (
        <div
          className={`${styles.heroBackground} pt-[90px] pb-16 flex flex-row overflow-x-auto space-x-10`}
        >
          {indiaData &&
            indiaData.map((ele, i) => {
              return (
                <div className=" flex-shrink-0" key={i}>
                  <div className={style.watchlistHeading}>
                    <div>
                      <div className="text-white font-semibold text-2xl">
                        {" "}
                        Portfolio : {ele.frontend_table_name}
                      </div>
                      <div className={style.tagDiv2}>
                          <div className="text-xs">Strategy Name</div>
                          {ele.table_name}
                        </div>
                      {/* <div className="flex flex-row mt-3 items-center justify-center">
                        <div className={style.tagDiv1}>
                          <div className="text-xs">Live Date</div>
                          {Moment(ele.live_date).format("DD/MM/YYYY")}
                        </div>
                        <div className={style.tagDiv2}>
                          <div className="text-xs">Broker</div>
                          {ele.broker}
                        </div>
                        <div className={style.tagDiv2}>
                          <div className="text-xs">User Name</div>
                          {ele.user_name}
                        </div>
                      </div>
                      <div className="flex flex-row mt-3 items-center justify-center">
                        <div className={style.tagDiv2}>
                          <div className="text-xs">Invested Amount</div>
                          {ele.inversted_amount === "1000000"
                            ? "INR 10Lakh"
                            : ele.inversted_amount}
                        </div>
                        <div className={style.tagDiv2}>
                          <div className="text-xs">Strategy Name</div>
                          {ele.table_name}
                        </div>
                      </div> */}
                    </div>

                    <div className="flex flex-row justify-center">
                      <TableComponent
                        watchlist={niftyData && niftyData[ele.table_name]}
                        watchlistSell={
                          niftySellData && niftySellData[ele.table_name]
                        }
                       
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          className={`${styles.heroBackground} pt-32 pb-16 flex flex-row overflow-x-auto space-x-10`}
        >
          {usaData &&
            usaData.map((ele, i) => {
              return (
                <div className="flex-shrink-0" key={i}>
                  <div className={style.watchlistHeading}>
                    <div>
                      <div className="text-white font-semibold text-2xl">
                        {" "}
                        Portfolio : {ele.frontend_table_name}
                      </div>
                      <div className={style.tagDiv2}>
                          <div className="text-xs">Strategy Name</div>
                          {ele.table_name}
                        </div>
                      {/* <div className="flex flex-row mt-3 items-center justify-center">
                        <div className={style.tagDiv1}>
                          <div className="text-xs">Live Date</div>
                          {Moment(ele.live_date).format("DD/MM/YYYY")}
                        </div>
                        <div className={style.tagDiv2}>
                          <div className="text-xs">Broker</div>
                          {ele.broker}
                        </div>
                        <div className={style.tagDiv2}>
                          <div className="text-xs">User Name</div>
                          {ele.user_name}
                        </div>
                      </div>
                      <div className="flex flex-row mt-3 items-center justify-center">
                        <div className={style.tagDiv2}>
                          <div className="text-xs">Invested Amount</div>
                          {i === 0
                            ? "$20k"
                            : i === 1
                            ? "$50K"
                            : i === 2
                            ? "$10k"
                            : ele.inversted_amount}
                        </div>
                        <div className={style.tagDiv2}>
                          <div className="text-xs">Strategy Name</div>
                          {ele.table_name}
                        </div>
                      </div> */}
                    </div>

                    <div className="flex flex-row justify-center">
                      <TableComponent
                        watchlist={niftyData && niftyData[ele.table_name]}
                        watchlistSell={
                          niftySellData && niftySellData[ele.table_name]
                        }

                     
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
