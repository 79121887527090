import React from "react";
import styles from "./HomePage.module.css";
const TableComponent = (props) => {
  return (
    <table className={`${styles.table}  mt-3`}>
      <thead>
        <tr>
          <th scope="col">Name of Stock</th>
          <th scope="col">RSR</th>
          <th scope="col">ID</th>
        </tr>
      </thead>
      <tbody>
        {props?.watchlist &&
          props?.watchlist
            ?.sort((a, b) => b[2] - a[2])
            ?.map((ele, i) => {
              return (
                <tr key={i}>
                  <td className="">
                    <div className="flex flex-row items-center">
                      {ele.tag === "buy" ? (
                        <span className="h-3 w-3 rounded-full bg-[#18B13F] mr-3"></span>
                      ) : ele.tag === "sell" ? (
                        <span className="h-3 w-3 rounded-full bg-[#E7351D] mr-3"></span>
                      ) : (
                        <span className="h-3 w-3 rounded-full bg-[#EC9513] mr-3"></span>
                      )}

                      <div className="flex flex-col items-start">
                        <div className="text-[12px]">{ele?.company_name}</div>
                        {ele?.industry && (
                          <span className="text-[10px] h-[20px] bg-zinc-700 px-2 text-white flex items-center rounded-[4px]">
                            {ele?.industry}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>

                  <td className="text-[12px]">{ele?.rsr.toFixed(2)}</td>
                  <td className="text-[12px]">{ele?.id?.toFixed(2)}</td>
                </tr>
              );
            })}
        {props.watchlistSell &&
          props.watchlistSell
            .sort((a, b) => a.id - b.id)
            .map((ele, i) => {
              return (
                <tr key={i}>
                  <td className="">
                    <div className="flex flex-row items-center text-[12px]">
                      {ele.tag === "buy" ? (
                        <span className="h-3 w-3 rounded-full bg-[#18B13F] mr-3"></span>
                      ) : ele.tag === "sell" ? (
                        <span className="h-3 w-3 rounded-full bg-[#E7351D] mr-3"></span>
                      ) : (
                        <span className="h-3 w-3 rounded-full bg-[#EC9513] mr-3"></span>
                      )}

                      <div className="flex flex-col items-start">
                        <div className="text-[12px]">{ele?.company_name}</div>
                        {ele?.industry && (
                          <span className="text-[10px] h-[20px] bg-zinc-700 px-2 text-white flex items-center rounded-[4px]">
                            {ele?.industry}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>

                  <td className="text-[12px]">{ele?.rsr.toFixed(2)}</td>
                  <td className="text-[12px]">{ele?.id?.toFixed(2)}</td>
                </tr>
              );
            })}
      </tbody>
    </table>
  );
};

export default TableComponent;
